<template>
  <div class="login-page">
    <van-nav-bar left-text="返回" left-arrow @click-left="onClickLeft" />
    <div class="register-container">
      <!-- 账号密码登录 -->
      <div v-if="this.setting.accountLogin && ZhangHaoLogin">
        <h2 class="login-title">账号密码注册</h2>

        <van-form @submit="handleRegister">
          <van-cell-group inset>
            <div class="form-group active">
              <van-field
                v-model="loginForm.username"
                name="用户名"
                label="用户名"
                placeholder="请输入用户名"
                :rules="[{ required: true, message: '请填写用户名' }]"
              />
            </div>
            <div class="form-group active">
              <van-field
                v-model="loginForm.password"
                type="password"
                name="密码"
                label="密码"
                placeholder="请输入密码"
                :rules="[{ required: true, message: '请填写密码' }]"
              />
            </div>

            <div class="form-group active">
              <van-field
                v-model="loginForm.checkPass"
                type="password"
                name="确认密码"
                label="确认密码"
                placeholder="请确认密码"
                :rules="[{ required: true, message: '请填写密码' }]"
              />
            </div>
          </van-cell-group>

          <div style="margin: 16px">
            <van-button round block type="primary" native-type="submit">
              立即注册
            </van-button>
          </div>
        </van-form>
      </div>

      <div class="rigest">
        <span>已有账号？</span>
        <span style="color: #ff594d" @click="handleLogin()">立即登录</span>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/style.css";
// import { getGuestToken, getUserId } from "@/utils/auth";
// import Cookies from "js-cookie";
import { Toast } from "vant";
import { mapState } from "vuex";
import { sendPhoneCode } from "@/api/user";

export default {
  data() {
    return {
      // getUserId: getUserId(),
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
        phoneNumber: "",
        smsVerificationCode: "",
      },
      labelPosition: "top",
      isSuccess: true,
      isLoading: false,
      YanZhengMa: false,
      ZhangHaoLogin: false,
      countdown: 0,
      buttonText: "获取验证码",
    };
  },
  computed: {
    ...mapState({
      webSetting: (state) => state.index.webSetting,

      //查询网站系统配置
      setting: (state) => state.index.setting,
    }),
  },
  mounted() {
    // 根据后台返回的值设置默认选中的标签页
    if (this.setting.accountLogin) {
      this.ZhangHaoLogin = true;
    } else if (this.setting.phoneLogin) {
      this.YanZhengMa = true;
    }
  },

  created() {},

  methods: {
    toYanZhengMaLogin() {
      this.YanZhengMa = true;
      this.ZhangHaoLogin = false;
    },
    toZhangHaoLogin() {
      this.ZhangHaoLogin = true;
      this.YanZhengMa = false;
    },

    /**
     * 注册
     */
    handleRegister() {
      if (this.loginForm.checkPass !== this.loginForm.password) {
        Toast("两次输入的密码不一致");
        return;
      }

      this.$store
        .dispatch("user/register", this.loginForm)
        .then(() => {
          this.$store.dispatch("user/login", this.loginForm).then(() => {
            Toast.success({
              message: "注册成功",
            });
            this.$router.push("/mindex");
          });
        })
        .catch(() => {});
    },

    /**
     * 手机号注册
     */
    phoneRegister() {
      if (this.inviter_code) {
        this.loginForm.inviterCode = this.inviter_code;
      }
      this.$store
        .dispatch("user/phoneRegister", this.loginForm)
        .then(() => {
          this.$store.dispatch("user/login", this.loginForm).then(() => {
            Toast.success({
              message: "注册成功",
            });
            this.$router.push("/mindex");
          });
        })
        .catch(() => {});
    },

    /**
     * 发送获取验证码
     */
    startCountdown() {
      new Promise((resolve, rejust) => {
        sendPhoneCode(this.loginForm)
          .then((res) => {
            if (res.status == 0) {
              Toast.success({
                message: "已发送",
              });

              this.countdown = 60;
              this.buttonText = `${this.countdown} 秒后重试`;

              const timer = setInterval(() => {
                if (this.countdown > 0) {
                  this.countdown--;
                  this.buttonText = `${this.countdown} 秒后重试`;
                } else {
                  clearInterval(timer);
                  this.buttonText = "获取验证码";
                }
              }, 1000);
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 登录界面
     */

    handleLogin() {
      this.$router.push("/login");
    },

    /**
     * 返回主页
     */
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>

<style scoped>
.login-page {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  width: 100vw;
  min-height: 100vh;
  background-color: #ffffff;
  /* padding: 2rem; */
}

.register-container {
  margin-top: 3rem;
}

.login-title {
  color: #1e2331;
  font-weight: 600;
  font-size: 1.3125rem;
  margin: 0;
  padding: 0 20px;
  margin-bottom: 20px;
}

.register-title {
  color: #1e2331;
  font-weight: 600;
  font-size: 1.3125rem;
  margin: 0;
  padding: 0 20px;
}

.login-page .other {
  width: 19.3125rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.625rem;
  color: #ff594d;
  font-weight: 400;
  font-size: 0.875rem;
}

.login-page .rigest {
  width: 100%;
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.8125rem;
  margin-top: 1.46875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
