<template>
  <div class="login-page">
    <van-nav-bar
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
      style=""
    />
    <div class="login-container">
      <!-- <div class="login-title">
        <img :src="webSetting.detail.webLogo" style="width: 70px" />
      </div> -->
      <h4 class="login-title">欢迎登录</h4>

      <van-tabs v-model="activeName" style="margin-top: 40px">
        <van-tab v-if="setting.accountLogin">
          <template #title>
            <div class="title" style="font-size: 18px; font-weight: 700">
              账号/密码
            </div>
          </template>
          <div style="margin-top: 15px">
            <!-- 账号密码登录 -->
            <van-form @submit="handleLogin">
              <van-cell-group inset>
                <van-field
                  class="form-group"
                  v-model="loginForm.username"
                  name="用户名"
                  label="用户名"
                  placeholder="请输入用户名"
                  :rules="[{ required: true, message: '请填写用户名' }]"
                />
                <van-field
                  class="form-group"
                  v-model="loginForm.password"
                  type="password"
                  name="密码"
                  label="密码"
                  placeholder="请输入密码"
                  :rules="[{ required: true, message: '请填写密码' }]"
                />
              </van-cell-group>
              <div class="other">
                <!-- <span @click="toYanZhengMaLogin" v-if="setting.phoneLogin"
                  >验证码登录</span
                > -->
                <!-- <span>忘记密码?</span> -->
              </div>
              <div style="margin: 16px">
                <van-button
                  round
                  block
                  type="primary"
                  native-type="submit"
                  color="#3B7AFF"
                >
                  登录
                </van-button>
              </div>
            </van-form>
          </div>
        </van-tab>

        <van-tab v-if="setting.phoneLogin">
          <template #title>
            <div class="title" style="font-size: 18px; font-weight: 700">
              手机登录
            </div>
          </template>
          <!-- 验证码登录 -->
          <div style="margin-top: 15px">
            <van-form>
              <van-cell-group inset>
                <van-field
                  class="form-group"
                  v-model="loginForm.phoneNumber"
                  name="手机号"
                  label="手机号"
                  placeholder="请输入手机号"
                  :rules="[{ required: true, message: '请填写手机号' }]"
                />

                <van-field
                  class="form-group"
                  v-model="loginForm.smsVerificationCode"
                  center
                  clearable
                  label="短信验证码"
                  placeholder="请输入短信验证码"
                >
                  <template #button>
                    <van-button
                      size="small"
                      type="primary"
                      :disabled="countdown > 0"
                      @click="startCountdown()"
                      >{{ buttonText }}</van-button
                    >
                  </template>
                </van-field>
              </van-cell-group>
              <div class="other">
                <!-- <span @click="toZhangHaoLogin" v-if="setting.accountLogin"
                  >账号密码登录</span
                > -->
              </div>
              <div style="margin: 16px">
                <van-button
                  round
                  block
                  type="primary"
                  @click="handlePhoneLogin"
                  color="#3B7AFF"
                >
                  登录
                </van-button>
              </div>
            </van-form>
          </div>
        </van-tab>
      </van-tabs>

      <div class="rigest">
        <span>没有账号？</span>
        <span style="color: #ff594d" @click="handleRegister">立即注册</span>
      </div>

      <div class="more-login" v-if="this.setting.quickLogin">
        <span>第三方登录</span>

        <div class="login-icon-box">
          <span @click="jhLoginForm('qq')">
            <img class="icon" src="@/assets/icon/qq.svg" draggable="false" />
          </span>

          <span @click="jhLoginForm('wx')">
            <img
              class="icon"
              src="@/assets/icon/login_weixin.svg"
              draggable="false"
            />
          </span>

          <!-- <span @click="jhLoginForm('qq')">
            <img
              class="icon"
              src="../../assets/icon/icon_zhifubao.svg"
              draggable="false"
            />
          </span> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/style.css";
import { Toast } from "vant";
import { getScanLogin, sendPhoneCode } from "@/api/user";
import { mapState } from "vuex";

export default {
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        checkPass: "",
        inviterCode: "",
        phoneNumber: "",
        smsVerificationCode: "",
      },
      labelPosition: "top",
      isSuccess: true,
      inviter_code: "",
      ZhangHaoLogin: true,
      YanZhengMa: false,
      countdown: 0,
      buttonText: "获取验证码",
      activeName: "",
    };
  },
  computed: {
    ...mapState({
      webSetting: (state) => state.index.webSetting,
      //查询网站系统配置
      setting: (state) => state.index.setting,
    }),
  },
  mounted() {
    // 根据后台返回的值设置默认选中的标签页
    if (this.setting.accountLogin) {
      this.activeName = 0;
    } else if (this.setting.phoneLogin) {
      this.activeName = 1;
    } else {
      this.activeName = 0;
    }
  },
  methods: {
    toYanZhengMaLogin() {
      this.ZhangHaoLogin = false;
      this.YanZhengMa = true;
    },
    toZhangHaoLogin() {
      this.ZhangHaoLogin = true;
      this.YanZhengMa = false;
    },

    /**
     * 账号密码登录
     */
    handleLogin() {
      console.log("======================");
      if (this.inviter_code) {
        this.loginForm.inviterCode = this.inviter_code;
      }
      console.log("======================");

      this.$store
        .dispatch("user/login", this.loginForm)
        .then(() => {
          Toast.success({
            message: "登陆成功",
          });
          this.$router.push("/mindex");
        })
        .catch(() => {
          // this.isLoading = false;
        });
    },

    /**
     * 手机号登录
     */
    handlePhoneLogin() {
      if (this.inviter_code) {
        this.loginForm.inviterCode = this.inviter_code;
      }
      this.$store
        .dispatch("user/phoneLogin", this.loginForm)
        .then(() => {
          Toast.success({
            message: "登陆成功",
          });
          this.$router.push("/mindex");
        })
        .catch(() => {});
    },

    /**
     * 发送获取验证码
     */
    startCountdown() {
      new Promise((resolve, rejust) => {
        sendPhoneCode(this.loginForm)
          .then((res) => {
            if (res.status == 0) {
              Toast.success({
                message: "已发送",
              });

              this.countdown = 60;
              this.buttonText = `${this.countdown} 秒后重试`;

              const timer = setInterval(() => {
                if (this.countdown > 0) {
                  this.countdown--;
                  this.buttonText = `${this.countdown} 秒后重试`;
                } else {
                  clearInterval(timer);
                  this.buttonText = "获取验证码";
                }
              }, 1000);
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 聚合登录
     */
    jhLoginForm(type) {
      var data = {
        loginType: type,
      };
      new Promise((resolve, rejust) => {
        getScanLogin(data)
          .then((res) => {
            // this.domainList = res.result.list;
            if (res.result.code === 0) {
              // 获取第三方URL
              var url = res.result.url;

              // 在当前窗口中打开第三方URL
              window.location.href = url;
            }
            resolve();
          })
          .catch((error) => {
            rejust(error);
          });
      });
    },

    /**
     * 注册界面
     */

    handleRegister() {
      this.$router.push("/register");
    },

    /**
     * 返回主页
     */
    onClickLeft() {
      this.$router.push("/my");
    },
  },
};
</script>

<style scoped>
.login-page {
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: center; */
  width: 100vw;
  min-height: 100vh;
  background-color: #ffffff;
  /* padding: 2rem; */
}

.login-container {
  margin-top: 2rem;
}

.login-title {
  color: #1e2331;
  font-weight: 600;
  /* font-size: 1.3125rem; */
  margin: 0;
  padding: 0 20px;
  margin-bottom: 30px;
  text-align: center;
}

.login-page .other {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 0.625rem; */
  color: #ff594d;
  font-weight: 400;
  font-size: 0.875rem;
  padding: 10px 30px 0px 30px;
}

.login-page .rigest {
  width: 100%;
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.8125rem;
  margin-top: 1.46875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page .more-login {
  width: 100%;
  margin-top: 4.625rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 1.15625rem;
}

.login-page .more-login .text {
  font-weight: 400;
  color: #9b9fa8;
  font-size: 0.875rem;
  position: relative;
}

.login-page .more-login .login-icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3.5625rem;
}

.login-page .more-login .text :before {
  content: "";
  width: 4.375rem;
  height: 0.03125rem;
  background: #d6dae2;
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  right: 5.125rem;
}

.login-page .more-login .text :after {
  content: "";
  width: 4.375rem;
  height: 0.03125rem;
  background: #d6dae2;
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: 5.125rem;
}

.login-page .more-login .login-icon-box .icon {
  width: 2.4rem;
  height: 2.4rem;
  color: #cacdd5;
}
</style>
