import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
import Login from './pages/mobile/login.vue'
import Register from './pages/mobile/register.vue'
import { Message } from 'element-ui'
import Reset from './pages/reset.vue'
import mNoticeDetails from '@/pages/mobile/mNoticeDetails.vue';

Vue.use(VueRouter)

//PS:   keepAlive: false是不需要缓存  true是缓存

// 移动端路由配置 mobileRoutes.js
const mobile = [{
        path: '/login',
        component: Login
    },
    {
        path: '/register',
        component: Register
    }, {
        path: '/reset',
        component: Reset
    }, {
        path: '/mindex',
        name: "mindex",
        component: () =>
            import ('@/pages/mindex.vue'),
        meta: {
            title: '首页',
            keepAlive: true,
            requiresMobile: true,
        },
        props: true
    },
    {
        path: '/contact',
        component: () =>
            import ('@/pages/mobile/contact.vue'),
        meta: {
            keepAlive: true,
            requiresMobile: true,

        },
        props: true
    }, {
        path: '/cate',
        component: () =>
            import ('@/pages/mobile/cate.vue'),
        meta: {
            keepAlive: true,
            requiresMobile: true,
        },
        props: true
    }, {
        path: '/openshop',
        component: () =>
            import ('@/pages/mobile/openshop.vue'),
        meta: {
            title: '开通分站',
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/afterSales',
        component: () =>
            import ('@/pages/mobile/afterSales.vue'),
        meta: {
            title: '售后订单',
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/amountDetail',
        component: () =>
            import ('@/pages/mobile/amountDetail.vue'),
        meta: {
            title: '资金明细',
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/hotProduct',
        component: () =>
            import ('@/pages/mobile/hotProduct.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/product_list',
        component: () =>
            import ('@/pages/mobile/productList.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
        props: true

    }, {
        path: '/product_detail',
        component: () =>
            import ('@/pages/mobile/productDetail.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/setup',
        component: () =>
            import ('@/pages/mobile/setup.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/my',
        component: () =>
            import ('@/pages/mobile/my/index.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/userlevel',
        component: () =>
            import ('@/pages/mobile/userlevel.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/openVip',
        component: () =>
            import ('@/pages/mobile/openVip.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/api',
        component: () =>
            import ('@/pages/mobile/api.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/browselist',
        component: () =>
            import ('@/pages/mobile/browselist.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/collectlist',
        component: () =>
            import ('@/pages/mobile/collectlist.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/search',
        component: () =>
            import ('@/pages/mobile/search/index.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/payment',
        component: () =>
            import ('@/pages/mobile/payment/index.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/order',
        component: () =>
            import ('@/pages/mobile/order/index.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/queryOrder',
        component: () =>
            import ('@/pages/mobile/order/queryOrder.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/order_detail',
        component: () =>
            import ('@/pages/mobile/order/orderDetail.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/visitor_order_detail',
        component: () =>
            import ('@/pages/mobile/order/visitor_order_detail.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/detail',
        component: () =>
            import ('@/pages/mobile/orderDetail.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/revenueDetail',
        component: () =>
            import ('@/pages/mobile/revenueDetail.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    }, {
        path: '/tuiguang',
        component: () =>
            import ('@/pages/mobile/tuiguang.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
    },
    {
        path: '/mbalance',
        component: () =>
            import ('@/pages/mobile/balance.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
        props: true
    },
    {
        path: '/mwithdrawal',
        component: () =>
            import ('@/pages/mobile/mwithdrawal.vue'),
        meta: {
            keepAlive: false,
            requiresMobile: true,
        },
        props: true
    },
    {
        path: '/mNoticeDetails/:id',
        name: 'mNoticeDetails',
        component: mNoticeDetails,
        meta: {
            keepAlive: true,
            requiresMobile: true,
        },
        props: true
    },
    {
        path: '/bangzhuzhongxin',
        component: () =>
            import ('@/pages/mobile/bangzhuzhongxin.vue'),
        meta: {
            keepAlive: true,
            requiresMobile: true,
        },
        props: true
    },
    {
        path: '/sysNotice',
        component: () =>
            import ('@/pages/mobile/sysNotice.vue'),
        meta: {
            keepAlive: true,
            requiresMobile: true,
        },
        props: true
    },
];




// PC端路由配置
const pcRoutes = [{
        path: '/plogin',
        component: () =>
            import ('@/pages/pc/login.vue'),
        meta: {
            keepAlive: true,
            requiresPC: true
        },
        props: true
    }, {
        path: '/page',
        component: () =>
            import ('@/pages/page.vue'),
        props: true,
        meta: {
            keepAlive: false,
            requiresPC: true,
        },
        children: [{
                path: '/puser',
                component: () =>
                    import ('@/pages/pc/user.vue'),
                meta: {
                    keepAlive: false,
                    requiresPC: true,
                },
                children: [{
                        path: '/porder',
                        component: () =>
                            import ('@/pages/pc/myorder.vue'),
                        meta: {
                            keepAlive: false,
                            requiresPC: true,
                        },
                    }, {
                        path: '/user_info',
                        component: () =>
                            import ('@/pages/pc/userInfo.vue'),
                        meta: {
                            keepAlive: false,
                            requiresPC: true,
                        }
                    }, {
                        path: '/service',
                        component: () =>
                            import ('@/pages/pc/service.vue'),
                        meta: {
                            keepAlive: false,
                            requiresPC: true,
                        }
                    }, {
                        path: '/balance',
                        component: () =>
                            import ('@/pages/pc/balance.vue'),
                        meta: {
                            keepAlive: false,
                            requiresPC: true,
                        }
                    },
                    {
                        //开放分站
                        path: '/openFz',
                        component: () =>
                            import ('@/pages/pc/openFz.vue')
                    },


                    {
                        path: '/credit',
                        component: () =>
                            import ('@/pages/pc/credit.vue'),
                        meta: {
                            keepAlive: false,
                            requiresPC: true,
                        }
                    }, {
                        path: '/cash',
                        component: () =>
                            import ('@/pages/pc/cash.vue'),
                        meta: {
                            keepAlive: false,
                            requiresPC: true,
                        }
                    },
                    {
                        path: '/profit',
                        component: () =>
                            import ('@/pages/pc/profit.vue')
                    }, {
                        path: '/settlement',
                        component: () =>
                            import ('@/pages/pc/settlement.vue')
                    },
                    // {
                    //     path: '/fz/banner',
                    //     component: () =>
                    //         import ('@/pages/pc/fz/banner/banner.vue')
                    // }, {
                    //     path: '/fz/mBanner',
                    //     component: () =>
                    //         import ('@/pages/pc/fz/banner/mBanner.vue')
                    // }, 
                    {
                        path: '/convertManage',
                        component: () =>
                            import ('@/pages/pc/convertManage.vue'),
                        meta: {
                            keepAlive: false,
                            requiresPC: true,
                        }
                    }
                ],
            }, {
                path: '/notice',
                component: () =>
                    import ('@/pages/pc/notice.vue'),
                meta: {
                    keepAlive: true,
                    requiresPC: true,
                },
                props: true
            }, {
                path: '/noticeDetails/:id',
                name: 'noticeDetails',
                component: () =>
                    import ('@/pages/pc/noticeDetails.vue'),
                meta: {
                    keepAlive: true,
                    requiresPC: true,
                },
                props: true
            },
            {
                path: '/category',
                component: () =>
                    import ('@/pages/pc/category.vue'),
                meta: {
                    keepAlive: true,
                    requiresPC: true
                },
                props: true
            }, {
                path: '/pcate',
                component: () =>
                    import ('@/pages/pc/pcate.vue'),
                meta: {
                    keepAlive: true,
                    requiresPC: true
                },
                props: true
            }, {
                path: '/customer',
                component: () =>
                    import ('@/pages/pc/customer.vue'),
                meta: {
                    keepAlive: true,
                    requiresPC: true
                },
                props: true
            }, {
                path: '/pgoodsDetail',
                component: () =>
                    import ('@/pages/pc/pgoodsDetail.vue'),
                meta: {
                    keepAlive: true,
                    requiresPC: true
                },
                props: true
            }, {
                path: '/confirmOrder',
                component: () =>
                    import ('@/pages/pc/confirmOrder.vue'),
                meta: {
                    keepAlive: true,
                    requiresPC: true
                },
                props: true
            }, {
                path: '/plist',
                name: 'plist',
                component: () =>
                    import ('@/pages/pc/plist.vue'),
                meta: {
                    keepAlive: true,
                    requiresPC: true
                },
                props: true
            }, {
                path: '/index',
                component: () =>
                    import ('@/pages/index.vue'),
                meta: {
                    keepAlive: true,
                    requiresPC: true,
                },
                props: true,

            }, {
                path: '/reset',
                component: Reset
            },

        ],
    },

    {
        path: '/footer',
        component: () =>
            import ('@/pages/pc/common/footer.vue'),
        meta: {
            keepAlive: true,
            requiresPC: true
        },
        props: true
    },
];


// 公共组件
const commonRoutes = [

    {
        path: '/',
        component: () =>
            import ('@/pages/home.vue'),
        props: true
    },
    {
        path: '/install',
        component: () =>
            import ('@/pages/install.vue'),
        props: true
    },
    {
        path: '/error',
        component: () =>
            import ('@/pages/error.vue'),
        props: true
    },
    {
        path: '/thirdlogin',
        component: () =>
            import ('@/pages/pc/thirdlogin.vue'),
        meta: {
            keepAlive: true,
            requiresMobile: true,
        },
        props: true
    },
    {
        path: '/scanLogin',
        component: () =>
            import ('@/pages/pc/scanLogin.vue'),
        meta: {
            keepAlive: true,
        },
        props: true
    }, {
        path: '/cdk',
        component: () =>
            import ('@/pages/cdk.vue'),
        meta: {
            keepAlive: false,
        },
    },
];


const vipRoutes = [{
    path: '/vip',
    component: () =>
        import ('@/pages/vip/index.vue'),
    props: true,
    meta: {
        keepAlive: false,
    },
    children: [{
            //分站首页
            path: '/vip',
            component: () =>
                import ('@/pages/vip/dataStatis.vue')
        },
        {
            //分站信息
            path: '/vip/sys',
            component: () =>
                import ('@/pages/vip/sys.vue')
        },
        {
            //分站客服
            path: '/vip/customer',
            component: () =>
                import ('@/pages/vip/customer.vue')
        },
        {
            //首页弹框
            path: '/vip/homeUp',
            component: () =>
                import ('@/pages/vip/homeUp.vue')
        },
        {
            //同步分站商品
            path: '/vip/product',
            component: () =>
                import ('@/pages/vip/product.vue')
        },

        {
            //分站订单
            path: '/vip/Order',
            component: () =>
                import ('@/pages/vip/order.vue')
        },
        {
            //用户管理
            path: '/vip/user',
            component: () =>
                import ('@/pages/vip/user.vue')
        },
        {
            //用户管理
            path: '/vip/commissionDetail',
            component: () =>
                import ('@/pages/vip/commissionDetail.vue')
        },
        {
            //PC端轮播图
            path: '/vip/banner/banner',
            component: () =>
                import ('@/pages/vip/banner/banner.vue')
        },
        {
            //PC端轮播图
            path: '/vip/banner/mBanner',
            component: () =>
                import ('@/pages/vip/banner/mBanner.vue')
        },
    ]
}, ];







const whiteList = ['/login', '/reset', '/sign', '/cart', '/order_form_create'] // no redirect whitelist
const createRouter = () => new VueRouter({
    mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        ...mobile,
        ...pcRoutes,
        ...commonRoutes,
        ...vipRoutes
    ]
})

const router = createRouter()

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


router.beforeEach(async(to, from, next) => {

    if (isMobile()) {
        // 如果在移动端访问PC端页面，则重定向到移动端对应页面
        if (to.matched.some(record => record.meta.requiresPC)) {

            next({ path: '/mindex' })
        } else {
            next()
        }
    } else {
        // 如果在PC端访问移动端页面，则重定向到PC端对应页面
        if (to.matched.some(record => record.meta.requiresMobile)) {
            next({ path: '/index' })

        } else {
            next()
        }
    }


    //获取登录信息
    const hasToken = getToken()
    if (hasToken) {
        // 获取认证状态
        const isAuthenticated = localStorage.getItem('isCardAuth') === 'true'
        if (isAuthenticated && window.location.pathname !== '/puser') {
            Message({
                message: localStorage.getItem('cardAuthTip'),
                type: 'info',
                duration: 5 * 1000
            });
            // next({ path: '/puser' });
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            //console.log("----")
        }
    }


})

//判断是否是移动端
function isMobile() {

    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    return flag;
}


router.afterEach(() => {})

export default router;