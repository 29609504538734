<template>
  <!-- 全局分页功能 -->
  <div class="content">
    <div class="block">
      <el-pagination
        @size-change="editPageSizes"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationPaging",
  props: {
    total: {
      type: Number, // 总条数
      default: 0,
    },
    currentPage: {
      type: Number, // 当前页
      default: 1,
    },
    pageSize: {
      type: Number, // 每页的条数
      default: 20,
    },
  },
  data() {
    return {
      options: [
        {
          value: "10",
          label: "10 条/页",
        },
        {
          value: "20",
          label: "20 条/页",
        },
        {
          value: "30",
          label: "30 条/页",
        },
        {
          value: "40",
          label: "40 条/页",
        },
        {
          value: "50",
          label: "50 条/页",
        },
        {
          value: "100",
          label: "100 条/页",
        },
      ],
      pageSizes: "",
    };
  },
  methods: {
    // 当前页事件
    handleCurrentChange(val) {
      this.$emit("currentChange", val);
    },
    // 用户自定义每页条数需要的触发事件
    editPageSizes(val) {
      this.$emit("editPagesizes", Number(val));
    },
  },
};
</script>

<style lang="scss" scoped></style>
