import request from '@/utils/request'


// 余额充值
export function create(data) {
    return request({
        url: '/api/user/recharge/create',
        method: 'post',
        data
    })
}

/**
 * 
 * @param 查询余额充值状态 
 * @returns 
 */
export function queryRechargeStatus(data) {
    return request({
        url: '/api/user/recharge/queryRechargeStatus',
        method: 'post',
        data
    })
}


// 加款卡充值
export function camiRecharge(data) {
    return request({
        url: '/api/user/recharge/camiRecharge',
        method: 'post',
        data
    })
}