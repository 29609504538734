export function decimalPrice(val) {
    if (typeof val !== "string") {
        val = String(val);
    }

    const splitPrice = val.split(".");
    const numb1 = splitPrice[0];
    var numb2 = "00";
    if (splitPrice.length > 1) {
        numb2 = splitPrice[1];
    }

    return (
        `<span style="font-size:14px;line-height:14px;color:#ff4f42">￥</span>` +
        `<span style="font-size:22px;color:#ff4f42;font-weight: 500">${numb1}</span>.<span style="font-size:20px;color:#ff4f42;font-weight: 500">${numb2}</span>`
    );
}


/**
 * 提取链接
 */
export function extractLink(value) {
    let link = "";
    const reg =
        /(https?|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
    const strValue = value.match(reg);
    if (strValue && strValue.length > 0) {
        link = strValue[0];
    }
    if (link === "") {
        this.$message({
            message: "链接提取失败",
            type: "error",
        });
        return;
    }
    return link;
}


/**
 * 商品价格
 */
export function calculateProductPrice(quantity, price, orderParamList) {
    let total;
    if (quantity >= 0 && price >= 0) {

        if (orderParamList && orderParamList.length > 0) {
            orderParamList.forEach(element => {
                if (element.type === "3" && element.value) {
                    quantity *= element.value;
                }
            });
        }

        let calculatedTotal = quantity * price;
        let fixedTotal = parseFloat(calculatedTotal.toFixed(6)).toString();
        total = fixedTotal;

    } else {
        // alert('Please enter valid quantity and price');
    }
    return total;
}